<template>
  <div class="n-share" :class="!show?'minh':''">
    <div class="title">Info -</div>
    <div class="infopage">
      <div class="wp1200">
      <div class="n-info">
        <div class="pic" data-aos="fade-up">
          <img :src="info.images" alt="" />
          <img class="ue" :src="info.sj_images" alt="" />
        </div>
        <div class="txt" data-aos="fade-up" data-aos-delay="200">
          <div v-html="info.content"></div>
        </div>
      </div>
    </div>
    <div class="line-13"></div>
    </div>
  </div>
</template>

<script>
import AOS from "aos";
export default {
  data () {
    return {
      show:false,
      loadsuc:false,
      info: ''
    }
  },
  methods: {
    getInfo () {
      let that = this
      that.$http
        .info()
        .then((res) => {
          if (res.ret == 0) {
            // console.log(res);
            setTimeout(function () {
              that.show = true
            }, 300);
            that.loadsuc=true
            let result = res.data
            AOS.init({
              duration: 1000,
            });
            that.info = result
          }
        })
    }
  },
  mounted () {

    this.getInfo();

  }
}
</script>

<style>
</style>